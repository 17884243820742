<template>
  <v-dialog
    v-model="status"
    persistent
    width="900"
  >
    <v-card>
      <v-card-title>
        Comissão do Afiliado: ID #{{ affiliateId }}
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="rows"
              item-key="id"
              class="table-rounded"
              disable-sort
              dense
            >
              <template #[`item.action`]="{item}">
                <v-btn
                  color="success"
                  dark
                  text
                  title="Editar"
                  x-small
                  @click="openFormToEdit(item.id)"
                >
                  <v-icon>{{ icons.mdiFileEdit }}</v-icon>
                </v-btn>
              </template>
              <template #[`item.serviceId`]="{item}">
                {{ resolveServiceName(item.serviceId) }}
              </template>
              <template #[`item.percentage`]="{item}">
                {{ $money(item.percentage, 0) }} %
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          class="pl-4"
          @click="openFormToCreate"
        >
          Novo Registro
          <v-icon
            right
            dark
          >
            {{ icons.mdiTablePlus }}
          </v-icon>
        </v-btn>

        <v-btn
          color="secondary"
          outlined
          @click="$emit('close')"
        >
          Fechar
        </v-btn>
      </v-card-actions>
    </v-card>

    <validation-observer
      ref="formAffiliate"
      autocomplete="off"
      tag="form"
      @keyup.enter="fetchAction"
    >
      <dialog-form
        :status="formDialogStatus"
        :is-update="formIsUpdate"
        :title="'Comissão de Afiliado'"
        :width="600"
        @action="fetchAction"
        @remove="removeDialogStatus = true"
        @close="formDialogStatus = !formDialogStatus"
      >
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <validation-provider
              v-slot="{ errors }"
              name="Serviço"
              rules="required"
              vid="form.serviceId"
            >
              <v-select
                v-model="form.serviceId"
                :error-messages="errors"
                :hide-details="errors.length === 0"
                :items="services"
                label="Serviço"
                dense
                outlined
                item-text="name"
                item-value="id"
              />
            </validation-provider>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <validation-provider
              v-slot="{ errors }"
              name="Comissão (%)"
              rules="required"
              vid="form.percentage"
            >
              <v-text-field
                v-model="form.percentage"
                :error-messages="errors"
                :hide-details="errors.length === 0"
                outlined
                dense
                label="Comissão (%)"
                type="number"
              />
            </validation-provider>
          </v-col>
        </v-row>
      </dialog-form>
    </validation-observer>

    <dialog-remove
      :status="removeDialogStatus"
      :text-to-confirm="form.name"
      @action="fetchActionRemove"
      @close="removeDialogStatus = !removeDialogStatus"
    >
    </dialog-remove>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { mdiFileEdit, mdiMagnify, mdiTablePlus } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  props: {
    status: {
      default: true,
      type: Boolean,
    },
    affiliateId: {
      type: Number,
      default: null,
    },
  },
  setup() {
    const services = ref([])
    const filterServiceId = ref(null)
    const formDialogStatus = ref(false)
    const removeDialogStatus = ref(false)
    const formIsUpdate = ref(false)
    const form = ref({
      id: ref(null),
      serviceId: ref(null),
      percentage: ref(0),
    })

    return {
      filterServiceId,
      form,
      formDialogStatus,
      removeDialogStatus,
      formIsUpdate,
      icons: {
        mdiFileEdit,
        mdiMagnify,
        mdiTablePlus,
      },
      headers: [
        { text: 'Ações', value: 'action', align: 'center' },
        { text: 'ID', value: 'id' },
        { text: 'Serviço', value: 'serviceId' },
        { text: 'Comissão (%)', value: 'percentage', align: 'right' },
      ],
      services,
    }
  },

  computed: {
    ...mapState('affiliateCustom', ['rows']),
  },

  watch: {
    affiliateId: {
      immediate: true,
      async handler(value) {
        await this.fetchCommissions()
      },
    },
  },

  async created() {
    this.services = await this.findServices()
  },

  methods: {
    ...mapActions('services', { findServices: 'find' }),
    ...mapActions('affiliateCustom', ['find', 'findById', 'create', 'update', 'remove']),

    resolveServiceName(serviceId) {
      const service = this.services.find(service => service.id === serviceId)

      if (service) {
        return service.name
      }

      return null
    },

    async openFormToCreate() {
      this.formDialogStatus = true
      this.formIsUpdate = false
      this.form = {
        id: null,
        serviceId: null,
        percentage: null,
      }
    },

    async openFormToEdit(rowId) {
      this.form.percentage = 0

      const affiliate = await this.findById(rowId)

      if (affiliate) {
        await this.$refs.formAffiliate.reset()
        this.formDialogStatus = true
        this.formIsUpdate = true
        this.form = {
          id: affiliate.id,
          serviceId: affiliate.serviceId,
          percentage: this.$money(affiliate.percentage, 0),
        }
      }
    },

    async fetchCommissions() {
      await this.find({
        serviceId: this.filterServiceId,
        affiliateId: this.affiliateId,
      })
    },

    async fetchAction(isUpdate) {
      const body = {
        serviceId: Number(this.form.serviceId),
        percentage: this.$strip(this.form.percentage),
        affiliateId: this.affiliateId,
      }

      if (await this.$refs.formAffiliate.validate()) {
        if (isUpdate) await this.update(body)
        else await this.create(body)

        this.fetchCommissions()
        this.formDialogStatus = false
      }
    },

    async fetchActionRemove() {
      try {
        if (this.form.id) {
          await this.remove(this.form.id)
        }
      } finally {
        this.fetchCommissions()
        this.formDialogStatus = false
        this.removeDialogStatus = false
      }
    },
  },
}
</script>
