var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"text-2xl"},[_vm._v(" Usuários ")]),_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',{staticClass:"pa-3"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6"}},[_c('v-text-field',{attrs:{"append-icon":_vm.icons.mdiMagnify,"label":"Nome","hide-details":"","dense":"","outlined":""},on:{"click:append":_vm.fetchUsers},model:{value:(_vm.filterName),callback:function ($$v) {_vm.filterName=$$v},expression:"filterName"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6"}},[_c('v-text-field',{attrs:{"append-icon":_vm.icons.mdiMagnify,"label":"E-mail","hide-details":"","dense":"","outlined":""},on:{"click:append":_vm.fetchUsers},model:{value:(_vm.filterEmail),callback:function ($$v) {_vm.filterEmail=$$v},expression:"filterEmail"}})],1)],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"pa-3"},[_c('v-btn',{staticClass:"pl-4",attrs:{"color":"primary"},on:{"click":_vm.fetchUsers}},[_vm._v(" Pesquisar "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiFileEdit)+" ")])],1)],1)],1),_c('v-card',{staticClass:"mt-5",attrs:{"flat":""}},[_c('v-data-table',{staticClass:"table-rounded",attrs:{"headers":_vm.headers,"items":_vm.rows,"item-key":"id","disable-sort":"","dense":""},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"success","dark":"","text":"","title":"Editar","x-small":""},on:{"click":function($event){return _vm.openFormToEdit(item.id)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiFileEdit))])],1),_c('v-btn',{attrs:{"color":"success","dark":"","text":"","title":"Tarifa","x-small":""},on:{"click":function($event){return _vm.openFormToCreateTax(item.id)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiCash))])],1),_c('v-btn',{attrs:{"color":"success","dark":"","text":"","title":"Comissão Afiliado","x-small":""},on:{"click":function($event){return _vm.openAffiliateCustomDialog(item.id)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPercentOutline))])],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2",attrs:{"color":item.status ? 'primary' : 'error',"small":"","label":""}},[_vm._v(" "+_vm._s(item.status ? 'Ativo' : 'Inativo')+" ")])]}},{key:"item.document",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$cpfCnpj.toMask(item.document))+" ")]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$phone.toMask(item.phone))+" ")]}},{key:"item.role",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.roles.find(function (role) { return role.id === item.role; }).text)+" ")]}},{key:"item.customerLevelId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.resolveUserLevel(item.customerLevelId))+" ")]}},{key:"item.isAffiliate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.isAffiliate ? 'Sim' : 'Não')+" ")]}}],null,true)})],1)],1)],1),_c('validation-observer',{ref:"formUser",attrs:{"autocomplete":"off","tag":"form"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.fetchAction.apply(null, arguments)}}},[_c('dialog-form',{attrs:{"status":_vm.formDialogStatus,"is-update":_vm.formIsUpdate,"title":'Usuário',"width":1200},on:{"action":_vm.fetchAction,"remove":function($event){_vm.removeDialogStatus = true},"close":function($event){_vm.formDialogStatus = !_vm.formDialogStatus}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Nome","rules":"required|max:100","vid":"form.name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"hide-details":errors.length === 0,"label":"Nome","outlined":"","dense":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"value":_vm.form.email,"label":"E-mail","hide-details":"","disabled":"","dense":"","outlined":""}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(['###.###.###-##', '##.###.###/####-##']),expression:"['###.###.###-##', '##.###.###/####-##']"}],attrs:{"label":"CPF/CNPJ","hide-details":"","disabled":"","dense":"","outlined":""},model:{value:(_vm.form.document),callback:function ($$v) {_vm.$set(_vm.form, "document", $$v)},expression:"form.document"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Telefone","rules":"required","vid":"form.phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(['(##) ####-####', '(##) #####-####']),expression:"['(##) ####-####', '(##) #####-####']"}],attrs:{"error-messages":errors,"hide-details":errors.length === 0,"label":"Telefone","outlined":"","dense":""},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"3","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Status","rules":"required","vid":"form.status"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":[
                { value: true, text: 'Ativo' },
                { value: false, text: 'Inativo' } ],"hide-details":errors.length === 0,"label":"Status","outlined":"","dense":"","item-text":"text","item-value":"value"},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"3","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Função","rules":"required","vid":"form.role"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.roles,"hide-details":errors.length === 0,"label":"Função","outlined":"","item-text":"text","item-value":"id","dense":""},model:{value:(_vm.form.role),callback:function ($$v) {_vm.$set(_vm.form, "role", $$v)},expression:"form.role"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"3","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Função","rules":"required","vid":"form.customerLevelId"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.userLevels,"hide-details":errors.length === 0,"label":"Nivel","outlined":"","item-text":"text","item-value":"id","dense":""},model:{value:(_vm.form.customerLevelId),callback:function ($$v) {_vm.$set(_vm.form, "customerLevelId", $$v)},expression:"form.customerLevelId"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"3","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Afiliado","rules":"required","vid":"form.isAffiliate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.isAffiliateItems,"hide-details":errors.length === 0,"label":"Afiliado","outlined":"","item-text":"text","item-value":"value","dense":""},model:{value:(_vm.form.isAffiliate),callback:function ($$v) {_vm.$set(_vm.form, "isAffiliate", $$v)},expression:"form.isAffiliate"}})]}}])})],1)],1)],1)],1),_c('validation-observer',{ref:"formTax",attrs:{"autocomplete":"off","tag":"form"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.fetchActionTaxUser.apply(null, arguments)}}},[_c('dialog-form',{attrs:{"status":_vm.formDialogTaxUserStatus,"is-update":!!_vm.formTaxUser.id,"width":900,"hide-save-btn":"","hide-remove-btn":"","title":"Tarifa do usuário"},on:{"action":_vm.fetchActionTaxUser,"remove":function($event){_vm.removeDialogTaxUserStatus = true},"close":function($event){_vm.formDialogTaxUserStatus = !_vm.formDialogTaxUserStatus}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Serviço","rules":"required","vid":"formTaxUser.serviceId"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"hide-details":errors.length === 0,"items":_vm.services,"label":"Serviço","outlined":"","dense":"","item-text":"name","item-value":"id"},on:{"change":_vm.getServiceToEdit},model:{value:(_vm.formTaxUser.serviceId),callback:function ($$v) {_vm.$set(_vm.formTaxUser, "serviceId", $$v)},expression:"formTaxUser.serviceId"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Valor unitário (R$)","rules":"required","vid":"formTaxUser.unitaryValue"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"money",rawName:"v-money",value:({
                precision: 4,
              }),expression:"{\n                precision: 4,\n              }"}],ref:"unitaryValue",attrs:{"error-messages":errors,"hide-details":errors.length === 0,"label":"Valor unitário (R$)","outlined":"","dense":""},model:{value:(_vm.formTaxUser.unitaryValue),callback:function ($$v) {_vm.$set(_vm.formTaxUser, "unitaryValue", $$v)},expression:"formTaxUser.unitaryValue"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-card-actions',{staticClass:"pa-0 ma-0"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.fetchActionTaxUser(_vm.formTaxUser.id)}}},[_vm._v(" Salvar ")])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-card',{staticClass:"mt-5",attrs:{"flat":""}},[_c('v-data-table',{staticClass:"table-rounded",attrs:{"headers":_vm.headersTaxes,"items":_vm.rowsTaxes,"item-key":"id","dense":"","disable-sort":""},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
              var item = ref.item;
return [_c('v-btn',{attrs:{"color":"success","dark":"","text":"","title":"Editar","x-small":""},on:{"click":function($event){return _vm.setCustomerTaxToEdit(item)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiFileEdit))])],1),_c('v-btn',{attrs:{"color":"error","text":"","small":"","fab":""},on:{"click":function($event){return _vm.fetchActionRemoveCustomerTax(item.id)}}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiDelete)+" ")])],1)]}},{key:"item.unitaryValue",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$money(item.unitaryValue, 4))+" ")]}}],null,true)})],1)],1)],1)],1)],1),_c('dialog-remove',{attrs:{"status":_vm.removeDialogStatus,"text-to-confirm":_vm.form.name},on:{"action":_vm.fetchActionRemove,"close":function($event){_vm.removeDialogStatus = !_vm.removeDialogStatus}}}),_c('affiliate-custom',{attrs:{"status":_vm.dialogAffiliateCustom,"affiliate-id":_vm.userId},on:{"close":function($event){_vm.dialogAffiliateCustom = !_vm.dialogAffiliateCustom}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }