<template>
  <div>
    <v-row>
      <v-col cols="12">
        <p class="text-2xl">
          Usuários
        </p>
        <v-card flat>
          <v-card-text class="pa-3">
            <v-row>
              <v-col
                cols="12"
                md="4"
                sm="6"
              >
                <v-text-field
                  v-model="filterName"
                  :append-icon="icons.mdiMagnify"
                  label="Nome"
                  hide-details
                  dense
                  outlined
                  @click:append="fetchUsers"
                />
              </v-col>
              <v-col
                cols="12"
                md="4"
                sm="6"
              >
                <v-text-field
                  v-model="filterEmail"
                  :append-icon="icons.mdiMagnify"
                  label="E-mail"
                  hide-details
                  dense
                  outlined
                  @click:append="fetchUsers"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider />
          <v-card-actions class="pa-3">
            <v-btn
              color="primary"
              class="pl-4"
              @click="fetchUsers"
            >
              Pesquisar
              <v-icon
                right
                dark
              >
                {{ icons.mdiFileEdit }}
              </v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>

        <v-card
          flat
          class="mt-5"
        >
          <v-data-table
            :headers="headers"
            :items="rows"
            item-key="id"
            class="table-rounded"
            disable-sort
            dense
          >
            <template #[`item.action`]="{ item }">
              <v-btn
                color="success"
                dark
                text
                title="Editar"
                x-small
                @click="openFormToEdit(item.id)"
              >
                <v-icon>{{ icons.mdiFileEdit }}</v-icon>
              </v-btn>
              <v-btn
                color="success"
                dark
                text
                title="Tarifa"
                x-small
                @click="openFormToCreateTax(item.id)"
              >
                <v-icon>{{ icons.mdiCash }}</v-icon>
              </v-btn>
              <v-btn
                color="success"
                dark
                text
                title="Comissão Afiliado"
                x-small
                @click="openAffiliateCustomDialog(item.id)"
              >
                <v-icon>{{ icons.mdiPercentOutline }}</v-icon>
              </v-btn>
            </template>
            <template #[`item.status`]="{ item }">
              <v-chip
                class="ma-2"
                :color="item.status ? 'primary' : 'error'"
                small
                label
              >
                {{ item.status ? 'Ativo' : 'Inativo' }}
              </v-chip>
            </template>
            <template #[`item.document`]="{ item }">
              {{ $cpfCnpj.toMask(item.document) }}
            </template>
            <template #[`item.phone`]="{ item }">
              {{ $phone.toMask(item.phone) }}
            </template>
            <template #[`item.role`]="{ item }">
              {{ roles.find(role => role.id === item.role).text }}
            </template>
            <template #[`item.customerLevelId`]="{ item }">
              {{ resolveUserLevel(item.customerLevelId) }}
            </template>
            <template #[`item.isAffiliate`]="{ item }">
              {{ item.isAffiliate ? 'Sim' : 'Não' }}
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <validation-observer
      ref="formUser"
      autocomplete="off"
      tag="form"
      @keyup.enter="fetchAction"
    >
      <dialog-form
        :status="formDialogStatus"
        :is-update="formIsUpdate"
        :title="'Usuário'"
        :width="1200"
        @action="fetchAction"
        @remove="removeDialogStatus = true"
        @close="formDialogStatus = !formDialogStatus"
      >
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <validation-provider
              v-slot="{ errors }"
              name="Nome"
              rules="required|max:100"
              vid="form.name"
            >
              <v-text-field
                v-model="form.name"
                :error-messages="errors"
                :hide-details="errors.length === 0"
                label="Nome"
                outlined
                dense
              />
            </validation-provider>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              :value="form.email"
              label="E-mail"
              hide-details
              disabled
              dense
              outlined
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="form.document"
              v-mask="['###.###.###-##', '##.###.###/####-##']"
              label="CPF/CNPJ"
              hide-details
              disabled
              dense
              outlined
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
            sm="6"
          >
            <validation-provider
              v-slot="{ errors }"
              name="Telefone"
              rules="required"
              vid="form.phone"
            >
              <v-text-field
                v-model="form.phone"
                v-mask="['(##) ####-####', '(##) #####-####']"
                :error-messages="errors"
                :hide-details="errors.length === 0"
                label="Telefone"
                outlined
                dense
              />
            </validation-provider>
          </v-col>
          <v-col
            cols="12"
            md="3"
            sm="6"
          >
            <validation-provider
              v-slot="{ errors }"
              name="Status"
              rules="required"
              vid="form.status"
            >
              <v-select
                v-model="form.status"
                :error-messages="errors"
                :items="[
                  { value: true, text: 'Ativo' },
                  { value: false, text: 'Inativo' },
                ]"
                :hide-details="errors.length === 0"
                label="Status"
                outlined
                dense
                item-text="text"
                item-value="value"
              />
            </validation-provider>
          </v-col>
          <v-col
            cols="12"
            md="3"
            sm="6"
          >
            <validation-provider
              v-slot="{ errors }"
              name="Função"
              rules="required"
              vid="form.role"
            >
              <v-select
                v-model="form.role"
                :error-messages="errors"
                :items="roles"
                :hide-details="errors.length === 0"
                label="Função"
                outlined
                item-text="text"
                item-value="id"
                dense
              />
            </validation-provider>
          </v-col>
          <v-col
            cols="12"
            md="3"
            sm="6"
          >
            <validation-provider
              v-slot="{ errors }"
              name="Função"
              rules="required"
              vid="form.customerLevelId"
            >
              <v-select
                v-model="form.customerLevelId"
                :error-messages="errors"
                :items="userLevels"
                :hide-details="errors.length === 0"
                label="Nivel"
                outlined
                item-text="text"
                item-value="id"
                dense
              />
            </validation-provider>
          </v-col>
          <v-col
            cols="12"
            md="3"
            sm="6"
          >
            <validation-provider
              v-slot="{ errors }"
              name="Afiliado"
              rules="required"
              vid="form.isAffiliate"
            >
              <v-select
                v-model="form.isAffiliate"
                :error-messages="errors"
                :items="isAffiliateItems"
                :hide-details="errors.length === 0"
                label="Afiliado"
                outlined
                item-text="text"
                item-value="value"
                dense
              />
            </validation-provider>
          </v-col>
        </v-row>
      </dialog-form>
    </validation-observer>

    <validation-observer
      ref="formTax"
      autocomplete="off"
      tag="form"
      @keyup.enter="fetchActionTaxUser"
    >
      <dialog-form
        :status="formDialogTaxUserStatus"
        :is-update="!!formTaxUser.id"
        :width="900"
        hide-save-btn
        hide-remove-btn
        title="Tarifa do usuário"
        @action="fetchActionTaxUser"
        @remove="removeDialogTaxUserStatus = true"
        @close="formDialogTaxUserStatus = !formDialogTaxUserStatus"
      >
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <validation-provider
              v-slot="{ errors }"
              name="Serviço"
              rules="required"
              vid="formTaxUser.serviceId"
            >
              <v-select
                v-model="formTaxUser.serviceId"
                :error-messages="errors"
                :hide-details="errors.length === 0"
                :items="services"
                label="Serviço"
                outlined
                dense
                item-text="name"
                item-value="id"
                @change="getServiceToEdit"
              />
            </validation-provider>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <validation-provider
              v-slot="{ errors }"
              name="Valor unitário (R$)"
              rules="required"
              vid="formTaxUser.unitaryValue"
            >
              <v-text-field
                ref="unitaryValue"
                v-model="formTaxUser.unitaryValue"
                v-money="{
                  precision: 4,
                }"
                :error-messages="errors"
                :hide-details="errors.length === 0"
                label="Valor unitário (R$)"
                outlined
                dense
              />
            </validation-provider>
          </v-col>
          <v-col
            cols="12"
            md="12"
          >
            <v-card-actions class="pa-0 ma-0">
              <v-spacer />
              <v-btn
                color="primary"
                @click="fetchActionTaxUser(formTaxUser.id)"
              >
                Salvar
              </v-btn>
            </v-card-actions>
          </v-col>
          <v-col
            cols="12"
            md="12"
          >
            <v-card
              flat
              class="mt-5"
            >
              <v-data-table
                :headers="headersTaxes"
                :items="rowsTaxes"
                item-key="id"
                dense
                class="table-rounded"
                disable-sort
              >
                <template #[`item.action`]="{ item }">
                  <v-btn
                    color="success"
                    dark
                    text
                    title="Editar"
                    x-small
                    @click="setCustomerTaxToEdit(item)"
                  >
                    <v-icon>{{ icons.mdiFileEdit }}</v-icon>
                  </v-btn>
                  <v-btn
                    color="error"
                    text
                    small
                    fab
                    @click="fetchActionRemoveCustomerTax(item.id)"
                  >
                    <v-icon>
                      {{ icons.mdiDelete }}
                    </v-icon>
                  </v-btn>
                </template>

                <template #[`item.unitaryValue`]="{ item }">
                  {{ $money(item.unitaryValue, 4) }}
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </dialog-form>
    </validation-observer>

    <dialog-remove
      :status="removeDialogStatus"
      :text-to-confirm="form.name"
      @action="fetchActionRemove"
      @close="removeDialogStatus = !removeDialogStatus"
    >
    </dialog-remove>

    <affiliate-custom
      :status="dialogAffiliateCustom"
      :affiliate-id="userId"
      @close="dialogAffiliateCustom = !dialogAffiliateCustom"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import {
  mdiFileEdit, mdiMagnify, mdiTablePlus, mdiCash, mdiDelete, mdiPercentOutline,
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import { userLevels, isAffiliateItems } from '@/utils/enum'
import AffiliateCustom from '@/views/admin/user/affiliateComission.vue'

export default {
  components: {
    AffiliateCustom,
  },
  setup() {
    const userId = ref(null)
    const filterName = ref(null)
    const filterEmail = ref(null)
    const formDialogStatus = ref(false)
    const formDialogTaxUserStatus = ref(false)
    const removeDialogStatus = ref(false)
    const formIsUpdate = ref(false)
    const form = ref({
      id: ref(null),
      name: ref(''),
      password: ref(''),
      email: ref(null),
      document: ref(null),
      phone: ref(null),
      status: ref(null),
      role: ref(null),
      customerLevelId: ref(null),
      isAffiliate: ref(false),
    })
    const formTaxUser = ref({
      id: null,
      userId: null,
      serviceId: null,
      unitaryValue: null,
    })

    const services = ref([])

    const resolveUserLevel = value => {
      const level = userLevels.find(level => level.id === value)

      if (level) {
        return level.text
      }

      return null
    }

    const dialogAffiliateCustom = ref(false)

    return {
      userId,
      filterName,
      filterEmail,
      form,
      formDialogStatus,
      formDialogTaxUserStatus,
      removeDialogStatus,
      formIsUpdate,
      userLevels,
      isAffiliateItems,
      resolveUserLevel,
      icons: {
        mdiFileEdit,
        mdiMagnify,
        mdiTablePlus,
        mdiCash,
        mdiDelete,
        mdiPercentOutline,
      },
      headers: [
        { text: 'Ações', value: 'action', align: 'center' },
        { text: 'ID', value: 'id' },
        { text: 'Nome', value: 'name' },
        { text: 'Status', value: 'status' },
        { text: 'E-mail', value: 'email' },
        { text: 'CPF/CNPJ', value: 'document' },
        { text: 'Telefone', value: 'phone' },
        { text: 'Função', value: 'role' },
        { text: 'Nível', value: 'customerLevelId' },
        { text: 'Afiliado', value: 'isAffiliate' },
      ],
      headersTaxes: [
        { text: 'Ações', value: 'action', align: 'center' },
        { text: 'ID', value: 'id' },
        { text: 'Serviço', value: 'serviceName' },
        { text: 'Valor unitário (R$)', value: 'unitaryValue' },
      ],
      services,
      formTaxUser,
      dialogAffiliateCustom,
    }
  },

  computed: {
    ...mapState('user', ['rows', 'roles', 'levels']),
    ...mapState('customerTaxes', {
      customerTaxesRows: 'rows',
      customerTaxRow: 'row',
    }),
    rowsTaxes() {
      return this.customerTaxesRows.map(item => {
        const service = this.services.find(i => i.id === item.serviceId)

        return {
          ...item,
          serviceName: service ? service.name : '',
        }
      })
    },
  },

  async created() {
    await this.fetchUsers()
    this.services = await this.findServices()
  },

  methods: {
    ...mapActions('services', { findServices: 'find' }),
    ...mapActions('customerTaxes', {
      findCustomerTaxes: 'find',
      findByIdCustomerTaxes: 'findById',
      createCustomerTax: 'create',
      updateCustomerTax: 'update',
      removeCustomerTax: 'remove',
    }),
    ...mapActions('user', ['find', 'findById', 'create', 'update', 'remove']),

    async openFormToEdit(rowId) {
      const user = await this.findById(rowId)

      if (user) {
        await this.$refs.formUser.reset()
        this.formDialogStatus = true
        this.formIsUpdate = true
        this.form = {
          id: user.id,
          name: user.name,
          password: user.password,
          email: user.email,
          document: user.document,
          phone: user.phone,
          status: user.status,
          role: user.role,
          customerLevelId: user.customerLevelId,
          isAffiliate: user.isAffiliate,
        }
      }
    },

    async getServiceToEdit(id) {
      const customerTax = this.customerTaxesRows.find(i => i.serviceId === id)
      if (customerTax) {
        this.formTaxUser = {
          id: customerTax.id,
          serviceId: customerTax.serviceId,
          userId: this.userId,
        }
        this.setUnitaryValue(customerTax.unitaryValue)
      } else {
        this.formTaxUser = {
          serviceId: id,
          userId: this.userId,
        }
        this.setUnitaryValue('0,00')
      }
    },

    async setCustomerTaxToEdit(item) {
      await this.findByIdCustomerTaxes(item.id)

      this.formTaxUser = {
        id: this.customerTaxRow.id,
        serviceId: this.customerTaxRow.serviceId,
        userId: this.userId,
      }

      this.setUnitaryValue(this.customerTaxRow.unitaryValue)
    },

    setUnitaryValue(unitaryValue) {
      const input = this.$refs.unitaryValue.$el.querySelector('input')
      input.value = this.$money(unitaryValue, 4)
      this.formTaxUser.unitaryValue = this.$money(unitaryValue, 4)
    },

    async fetchUsers() {
      await this.find({
        name: this.filterName,
        email: this.filterEmail,
      })
    },

    async fetchAction(isUpdate) {
      const body = {
        name: this.form.name,
        password: this.form.password,
        email: this.form.email,
        document: this.form.document.replace(/\D/g, ''),
        phone: this.form.phone.replace(/\D/g, ''),
        status: this.form.status,
        role: this.form.role,
        customerLevelId: this.form.customerLevelId,
        isAffiliate: this.form.isAffiliate,
      }

      if (await this.$refs.formUser.validate()) {
        if (isUpdate) await this.update(body)
        else await this.create(body)

        await this.fetchUsers()
        this.formDialogStatus = false
      }
    },

    async fetchActionRemove() {
      try {
        if (this.form.id) {
          await this.remove(this.form.id)
          await this.fetchUsers()
        }
      } finally {
        this.formDialogStatus = false
        this.removeDialogStatus = false
      }
    },

    async fetchActionRemoveCustomerTax(id) {
      await this.removeCustomerTax(id)
      this.formTaxUser = {
        serviceId: null,
        userId: this.userId,
        unitaryValue: null,
      }
      this.setUnitaryValue('0,00')
      await this.findCustomerTaxes({ userId: this.userId })
    },

    async openFormToCreateTax(rowId) {
      this.userId = rowId
      const customerUserTax = await this.findCustomerTaxes({ userId: rowId })

      await this.$refs.formTax.reset()
      this.formDialogTaxUserStatus = true

      if (customerUserTax.length) {
        this.formTaxUser = {
          id: customerUserTax[0].id,
          serviceId: customerUserTax[0].serviceId,
          userId: this.userId,
          unitaryValue: this.$money(customerUserTax[0].unitaryValue, 4),
        }
        await this.findByIdCustomerTaxes(customerUserTax[0].id)
      } else {
        this.formTaxUser = {
          serviceId: null,
          userId: this.userId,
          unitaryValue: null,
        }
      }
    },

    async fetchActionTaxUser(isUpdate) {
      if (this.formTaxUser.id) await this.findByIdCustomerTaxes(this.formTaxUser.id)
      const body = {
        id: this.formTaxUser.id,
        serviceId: this.formTaxUser.serviceId,
        userId: this.userId,
        unitaryValue: this.$strip(this.formTaxUser.unitaryValue, 2),
      }

      if (await this.$refs.formTax.validate()) {
        if (isUpdate) await this.updateCustomerTax(body)
        else await this.createCustomerTax(body)

        await this.findCustomerTaxes({ userId: this.userId })
        this.formDialogTaxUserStatus = true
      }
    },

    openAffiliateCustomDialog(userId) {
      this.userId = userId
      this.dialogAffiliateCustom = true
    },
  },
}
</script>
<style lang="scss">
.theme--dark {
  .v-chip {
    background-color: #06f11ab0 !important;
  }

  .v-application .primary {
    background: #06f11ab0 !important;
    background-color: white !important;
  }
}
</style>
